import { LogoutOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { contextStore } from '../../store/context';

type Props = {}

export const TopHeader = (props: Props) => {
  const { readonly, setReadonly } = useContext(contextStore)
  const onLogout = () => {
    localStorage.setItem("accessToken", "")
    window.location.reload()
  }
  const changeReadonly = () => {
    const targetMode = readonly ? "編輯模式" : "唯讀模式"
    if (readonly) {
      const yes = window.confirm(`進入${targetMode}`)
      if (!yes) {
        return
      }
    }
    
    setReadonly(!readonly)
  }
  return <Header className="site-layout-sub-header-background" style={{ padding: 0, color: "white", display: "flex", flexDirection: "row-reverse", alignItems: "center" }} >

    <Space>
      <Button type="primary" danger={!readonly} onClick={changeReadonly}>
        {
          readonly ?
            "目前為唯讀模式" :
            "目前為編輯模式"
        }
      </Button>
      <Button type="primary" onClick={onLogout}>
        <Link to="/">
          <LogoutOutlined />
          登出
        </Link>
      </Button>

    </Space>
  </Header>
}