import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  FileData: string;
  IP: string;
  Time: string;
  UUID: string;
};

export type Auth = {
  accessExpireAt: Scalars['Time'];
  accessToken: Scalars['UUID'];
};

export type Cert = {
  createdAt: Scalars['Time'];
  domain: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['Time'];
};

export type ConnectionResponse = {
  count: Scalars['Int'];
};

export type CreateAuthInput = {
  account: Scalars['String'];
  password: Scalars['String'];
};

export type CreateCertInput = {
  domain: Scalars['String'];
  sslCert: Scalars['String'];
  sslKey: Scalars['String'];
};

export type CreateReverseProxyInput = {
  actualDomain: Scalars['String'];
  actualPath: Scalars['String'];
  cdnDomain: Scalars['String'];
  cdnPath: Scalars['String'];
  comment: Scalars['String'];
  isWildCard: Scalars['Boolean'];
  maintenanceTitle?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateServerInput = {
  comment: Scalars['String'];
  name: Scalars['String'];
};

export type CreateUserInput = {
  account: Scalars['String'];
  role: Role;
};

export type DeleteResponse = {
  id: Scalars['ID'];
};

export type Mutation = {
  createAuthToken: Auth;
  createCert: Cert;
  createReverseProxy: ReverseProxy;
  createServer: Server;
  createUser: User;
  deleteCert: DeleteResponse;
  deleteReverseProxy: DeleteResponse;
  deleteServer: DeleteResponse;
  deleteUser: DeleteResponse;
  updateCert: Cert;
  updateReverseProxy: ReverseProxy;
  updateServer: Server;
  updateUser: User;
  updateUserSelf: User;
};


export type MutationCreateAuthTokenArgs = {
  input: CreateAuthInput;
};


export type MutationCreateCertArgs = {
  input: CreateCertInput;
};


export type MutationCreateReverseProxyArgs = {
  input: CreateReverseProxyInput;
};


export type MutationCreateServerArgs = {
  input: CreateServerInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteCertArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReverseProxyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteServerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCertArgs = {
  input: UpdateCertInput;
};


export type MutationUpdateReverseProxyArgs = {
  input: UpdateReverseProxyInput;
};


export type MutationUpdateServerArgs = {
  input: UpdateServerInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserSelfArgs = {
  input: UpdateUserSelfInput;
};

export type Query = {
  cert: Cert;
  certs: Array<Cert>;
  me: User;
  reverseProxies: Array<ReverseProxy>;
  reverseProxy: ReverseProxy;
  server: Server;
  servers: Array<Server>;
  test: Scalars['Boolean'];
  user: User;
  users: Array<User>;
};


export type QueryCertArgs = {
  id: Scalars['ID'];
};


export type QueryReverseProxyArgs = {
  id: Scalars['ID'];
};


export type QueryServerArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type ReverseProxy = {
  actualDomain: Scalars['String'];
  actualPath: Scalars['String'];
  cdnDomain: Scalars['String'];
  cdnPath: Scalars['String'];
  comment: Scalars['String'];
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  isActivated: Scalars['Boolean'];
  isMaintaining: Scalars['Boolean'];
  isTracking: Scalars['Boolean'];
  isWildCard: Scalars['Boolean'];
  maintenanceDesktopImageFilename: Scalars['String'];
  maintenanceMobileImageFilename: Scalars['String'];
  maintenanceTitle: Scalars['String'];
  name: Scalars['String'];
  port: Scalars['String'];
  proto: Scalars['String'];
  server?: Maybe<Server>;
  trackingPath: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export enum Role {
  Admin = 'admin',
  Manager = 'manager'
}

export type Server = {
  comment: Scalars['String'];
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  key: Scalars['UUID'];
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type UpdateCertInput = {
  domain?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateReverseProxyInput = {
  actualDomain?: InputMaybe<Scalars['String']>;
  actualPath?: InputMaybe<Scalars['String']>;
  cdnDomain?: InputMaybe<Scalars['String']>;
  cdnPath?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isActivated?: InputMaybe<Scalars['Boolean']>;
  isMaintaining?: InputMaybe<Scalars['Boolean']>;
  isTracking?: InputMaybe<Scalars['Boolean']>;
  isWildCard?: InputMaybe<Scalars['Boolean']>;
  maintenanceDesktopImageData?: InputMaybe<Scalars['FileData']>;
  maintenanceMobileImageData?: InputMaybe<Scalars['FileData']>;
  maintenanceTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  trackingPath?: InputMaybe<Scalars['String']>;
};

export type UpdateServerInput = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isActivated?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  unlockLoginFails?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserSelfInput = {
  newPassword?: InputMaybe<Scalars['String']>;
  oldPassword?: InputMaybe<Scalars['String']>;
  repeatNewPassword?: InputMaybe<Scalars['String']>;
};

export type User = {
  account: Scalars['String'];
  comment: Scalars['String'];
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  isActivated: Scalars['Boolean'];
  lastLoginAt: Scalars['Time'];
  lastLoginIP: Scalars['IP'];
  loginFailedAts: Array<Scalars['Time']>;
  loginFailedIPs: Array<Scalars['IP']>;
  password?: Maybe<Scalars['String']>;
  role: Role;
  updatedAt: Scalars['Time'];
};

export type CreateAuthTokenMutationVariables = Exact<{
  input: CreateAuthInput;
}>;


export type CreateAuthTokenMutation = { createAuthToken: { accessToken: string, accessExpireAt: string } };

export type CreateServerMutationVariables = Exact<{
  input: CreateServerInput;
}>;


export type CreateServerMutation = { createServer: { id: string, createdAt: string, updatedAt: string, name: string, key: string, comment: string } };

export type UpdateServerMutationVariables = Exact<{
  input: UpdateServerInput;
}>;


export type UpdateServerMutation = { updateServer: { id: string, createdAt: string, updatedAt: string, name: string, key: string, comment: string } };

export type DeleteServerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteServerMutation = { deleteServer: { id: string } };

export type CreateReverseProxyMutationVariables = Exact<{
  input: CreateReverseProxyInput;
}>;


export type CreateReverseProxyMutation = { createReverseProxy: { id: string, createdAt: string, updatedAt: string, isActivated: boolean, name: string, cdnDomain: string, actualDomain: string, cdnPath: string, actualPath: string, isWildCard: boolean, isMaintaining: boolean, maintenanceTitle: string, maintenanceDesktopImageFilename: string, maintenanceMobileImageFilename: string, isTracking: boolean, trackingPath: string, port: string, proto: string, comment: string, server?: { id: string, createdAt: string, updatedAt: string, name: string, key: string, comment: string } | null } };

export type UpdateReverseProxyMutationVariables = Exact<{
  input: UpdateReverseProxyInput;
}>;


export type UpdateReverseProxyMutation = { updateReverseProxy: { id: string, createdAt: string, updatedAt: string, isActivated: boolean, name: string, cdnDomain: string, actualDomain: string, cdnPath: string, actualPath: string, isWildCard: boolean, isMaintaining: boolean, maintenanceTitle: string, maintenanceDesktopImageFilename: string, maintenanceMobileImageFilename: string, isTracking: boolean, trackingPath: string, port: string, proto: string, comment: string, server?: { id: string, createdAt: string, updatedAt: string, name: string, key: string, comment: string } | null } };

export type DeleteReverseProxyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteReverseProxyMutation = { deleteReverseProxy: { id: string } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { id: string, createdAt: string, updatedAt: string, account: string, password?: string | null, isActivated: boolean, role: Role, lastLoginIP: string, lastLoginAt: string, loginFailedIPs: Array<string>, loginFailedAts: Array<string>, comment: string } };

export type UpdateUserSelfMutationVariables = Exact<{
  input: UpdateUserSelfInput;
}>;


export type UpdateUserSelfMutation = { updateUserSelf: { id: string, createdAt: string, updatedAt: string, account: string, password?: string | null, isActivated: boolean, role: Role, lastLoginIP: string, lastLoginAt: string, loginFailedIPs: Array<string>, loginFailedAts: Array<string>, comment: string } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: { id: string, createdAt: string, updatedAt: string, account: string, password?: string | null, isActivated: boolean, role: Role, lastLoginIP: string, lastLoginAt: string, loginFailedIPs: Array<string>, loginFailedAts: Array<string>, comment: string } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { deleteUser: { id: string } };

export type CreateCertMutationVariables = Exact<{
  input: CreateCertInput;
}>;


export type CreateCertMutation = { createCert: { id: string, createdAt: string, updatedAt: string, domain: string } };

export type UpdateCertMutationVariables = Exact<{
  input: UpdateCertInput;
}>;


export type UpdateCertMutation = { updateCert: { id: string, createdAt: string, updatedAt: string, domain: string } };

export type DeleteCertMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCertMutation = { deleteCert: { id: string } };

export type TestQueryVariables = Exact<{ [key: string]: never; }>;


export type TestQuery = { test: boolean };

export type ServerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ServerQuery = { server: { id: string, createdAt: string, updatedAt: string, name: string, key: string, comment: string } };

export type ServersQueryVariables = Exact<{ [key: string]: never; }>;


export type ServersQuery = { servers: Array<{ id: string, createdAt: string, updatedAt: string, name: string, key: string, comment: string }> };

export type ReverseProxyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReverseProxyQuery = { reverseProxy: { id: string, createdAt: string, updatedAt: string, isActivated: boolean, name: string, cdnDomain: string, actualDomain: string, cdnPath: string, actualPath: string, isWildCard: boolean, isMaintaining: boolean, maintenanceTitle: string, maintenanceDesktopImageFilename: string, maintenanceMobileImageFilename: string, isTracking: boolean, trackingPath: string, port: string, proto: string, comment: string, server?: { id: string, createdAt: string, updatedAt: string, name: string, key: string, comment: string } | null } };

export type ReverseProxiesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReverseProxiesQuery = { reverseProxies: Array<{ id: string, createdAt: string, updatedAt: string, isActivated: boolean, name: string, cdnDomain: string, actualDomain: string, cdnPath: string, actualPath: string, isWildCard: boolean, isMaintaining: boolean, maintenanceTitle: string, maintenanceDesktopImageFilename: string, maintenanceMobileImageFilename: string, isTracking: boolean, trackingPath: string, port: string, proto: string, comment: string, server?: { id: string, createdAt: string, updatedAt: string, name: string, key: string, comment: string } | null }> };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = { user: { id: string, createdAt: string, updatedAt: string, account: string, password?: string | null, isActivated: boolean, role: Role, lastLoginIP: string, lastLoginAt: string, loginFailedIPs: Array<string>, loginFailedAts: Array<string>, comment: string } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { users: Array<{ id: string, createdAt: string, updatedAt: string, account: string, password?: string | null, isActivated: boolean, role: Role, lastLoginIP: string, lastLoginAt: string, loginFailedIPs: Array<string>, loginFailedAts: Array<string>, comment: string }> };

export type CertQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CertQuery = { cert: { id: string, createdAt: string, updatedAt: string, domain: string } };

export type CertsQueryVariables = Exact<{ [key: string]: never; }>;


export type CertsQuery = { certs: Array<{ id: string, createdAt: string, updatedAt: string, domain: string }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { id: string, createdAt: string, updatedAt: string, account: string, password?: string | null, isActivated: boolean, role: Role, lastLoginIP: string, lastLoginAt: string, loginFailedIPs: Array<string>, loginFailedAts: Array<string>, comment: string } };


export const CreateAuthTokenDocument = gql`
    mutation CreateAuthToken($input: CreateAuthInput!) {
  createAuthToken(input: $input) {
    accessToken
    accessExpireAt
  }
}
    `;
export type CreateAuthTokenMutationFn = Apollo.MutationFunction<CreateAuthTokenMutation, CreateAuthTokenMutationVariables>;

/**
 * __useCreateAuthTokenMutation__
 *
 * To run a mutation, you first call `useCreateAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthTokenMutation, { data, loading, error }] = useCreateAuthTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuthTokenMutation, CreateAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuthTokenMutation, CreateAuthTokenMutationVariables>(CreateAuthTokenDocument, options);
      }
export type CreateAuthTokenMutationHookResult = ReturnType<typeof useCreateAuthTokenMutation>;
export type CreateAuthTokenMutationResult = Apollo.MutationResult<CreateAuthTokenMutation>;
export type CreateAuthTokenMutationOptions = Apollo.BaseMutationOptions<CreateAuthTokenMutation, CreateAuthTokenMutationVariables>;
export const CreateServerDocument = gql`
    mutation CreateServer($input: CreateServerInput!) {
  createServer(input: $input) {
    id
    createdAt
    updatedAt
    name
    key
    comment
  }
}
    `;
export type CreateServerMutationFn = Apollo.MutationFunction<CreateServerMutation, CreateServerMutationVariables>;

/**
 * __useCreateServerMutation__
 *
 * To run a mutation, you first call `useCreateServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServerMutation, { data, loading, error }] = useCreateServerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServerMutation(baseOptions?: Apollo.MutationHookOptions<CreateServerMutation, CreateServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServerMutation, CreateServerMutationVariables>(CreateServerDocument, options);
      }
export type CreateServerMutationHookResult = ReturnType<typeof useCreateServerMutation>;
export type CreateServerMutationResult = Apollo.MutationResult<CreateServerMutation>;
export type CreateServerMutationOptions = Apollo.BaseMutationOptions<CreateServerMutation, CreateServerMutationVariables>;
export const UpdateServerDocument = gql`
    mutation UpdateServer($input: UpdateServerInput!) {
  updateServer(input: $input) {
    id
    createdAt
    updatedAt
    name
    key
    comment
  }
}
    `;
export type UpdateServerMutationFn = Apollo.MutationFunction<UpdateServerMutation, UpdateServerMutationVariables>;

/**
 * __useUpdateServerMutation__
 *
 * To run a mutation, you first call `useUpdateServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServerMutation, { data, loading, error }] = useUpdateServerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServerMutation, UpdateServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServerMutation, UpdateServerMutationVariables>(UpdateServerDocument, options);
      }
export type UpdateServerMutationHookResult = ReturnType<typeof useUpdateServerMutation>;
export type UpdateServerMutationResult = Apollo.MutationResult<UpdateServerMutation>;
export type UpdateServerMutationOptions = Apollo.BaseMutationOptions<UpdateServerMutation, UpdateServerMutationVariables>;
export const DeleteServerDocument = gql`
    mutation DeleteServer($id: ID!) {
  deleteServer(id: $id) {
    id
  }
}
    `;
export type DeleteServerMutationFn = Apollo.MutationFunction<DeleteServerMutation, DeleteServerMutationVariables>;

/**
 * __useDeleteServerMutation__
 *
 * To run a mutation, you first call `useDeleteServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServerMutation, { data, loading, error }] = useDeleteServerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServerMutation, DeleteServerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServerMutation, DeleteServerMutationVariables>(DeleteServerDocument, options);
      }
export type DeleteServerMutationHookResult = ReturnType<typeof useDeleteServerMutation>;
export type DeleteServerMutationResult = Apollo.MutationResult<DeleteServerMutation>;
export type DeleteServerMutationOptions = Apollo.BaseMutationOptions<DeleteServerMutation, DeleteServerMutationVariables>;
export const CreateReverseProxyDocument = gql`
    mutation CreateReverseProxy($input: CreateReverseProxyInput!) {
  createReverseProxy(input: $input) {
    id
    server {
      id
      createdAt
      updatedAt
      name
      key
      comment
    }
    createdAt
    updatedAt
    isActivated
    name
    cdnDomain
    actualDomain
    cdnPath
    actualPath
    isWildCard
    isMaintaining
    maintenanceTitle
    maintenanceDesktopImageFilename
    maintenanceMobileImageFilename
    isTracking
    trackingPath
    port
    proto
    comment
  }
}
    `;
export type CreateReverseProxyMutationFn = Apollo.MutationFunction<CreateReverseProxyMutation, CreateReverseProxyMutationVariables>;

/**
 * __useCreateReverseProxyMutation__
 *
 * To run a mutation, you first call `useCreateReverseProxyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReverseProxyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReverseProxyMutation, { data, loading, error }] = useCreateReverseProxyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReverseProxyMutation(baseOptions?: Apollo.MutationHookOptions<CreateReverseProxyMutation, CreateReverseProxyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReverseProxyMutation, CreateReverseProxyMutationVariables>(CreateReverseProxyDocument, options);
      }
export type CreateReverseProxyMutationHookResult = ReturnType<typeof useCreateReverseProxyMutation>;
export type CreateReverseProxyMutationResult = Apollo.MutationResult<CreateReverseProxyMutation>;
export type CreateReverseProxyMutationOptions = Apollo.BaseMutationOptions<CreateReverseProxyMutation, CreateReverseProxyMutationVariables>;
export const UpdateReverseProxyDocument = gql`
    mutation UpdateReverseProxy($input: UpdateReverseProxyInput!) {
  updateReverseProxy(input: $input) {
    id
    server {
      id
      createdAt
      updatedAt
      name
      key
      comment
    }
    createdAt
    updatedAt
    isActivated
    name
    cdnDomain
    actualDomain
    cdnPath
    actualPath
    isWildCard
    isMaintaining
    maintenanceTitle
    maintenanceDesktopImageFilename
    maintenanceMobileImageFilename
    isTracking
    trackingPath
    port
    proto
    comment
  }
}
    `;
export type UpdateReverseProxyMutationFn = Apollo.MutationFunction<UpdateReverseProxyMutation, UpdateReverseProxyMutationVariables>;

/**
 * __useUpdateReverseProxyMutation__
 *
 * To run a mutation, you first call `useUpdateReverseProxyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReverseProxyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReverseProxyMutation, { data, loading, error }] = useUpdateReverseProxyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReverseProxyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReverseProxyMutation, UpdateReverseProxyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReverseProxyMutation, UpdateReverseProxyMutationVariables>(UpdateReverseProxyDocument, options);
      }
export type UpdateReverseProxyMutationHookResult = ReturnType<typeof useUpdateReverseProxyMutation>;
export type UpdateReverseProxyMutationResult = Apollo.MutationResult<UpdateReverseProxyMutation>;
export type UpdateReverseProxyMutationOptions = Apollo.BaseMutationOptions<UpdateReverseProxyMutation, UpdateReverseProxyMutationVariables>;
export const DeleteReverseProxyDocument = gql`
    mutation DeleteReverseProxy($id: ID!) {
  deleteReverseProxy(id: $id) {
    id
  }
}
    `;
export type DeleteReverseProxyMutationFn = Apollo.MutationFunction<DeleteReverseProxyMutation, DeleteReverseProxyMutationVariables>;

/**
 * __useDeleteReverseProxyMutation__
 *
 * To run a mutation, you first call `useDeleteReverseProxyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReverseProxyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReverseProxyMutation, { data, loading, error }] = useDeleteReverseProxyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReverseProxyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReverseProxyMutation, DeleteReverseProxyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReverseProxyMutation, DeleteReverseProxyMutationVariables>(DeleteReverseProxyDocument, options);
      }
export type DeleteReverseProxyMutationHookResult = ReturnType<typeof useDeleteReverseProxyMutation>;
export type DeleteReverseProxyMutationResult = Apollo.MutationResult<DeleteReverseProxyMutation>;
export type DeleteReverseProxyMutationOptions = Apollo.BaseMutationOptions<DeleteReverseProxyMutation, DeleteReverseProxyMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    createdAt
    updatedAt
    account
    password
    isActivated
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserSelfDocument = gql`
    mutation UpdateUserSelf($input: UpdateUserSelfInput!) {
  updateUserSelf(input: $input) {
    id
    createdAt
    updatedAt
    account
    password
    isActivated
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;
export type UpdateUserSelfMutationFn = Apollo.MutationFunction<UpdateUserSelfMutation, UpdateUserSelfMutationVariables>;

/**
 * __useUpdateUserSelfMutation__
 *
 * To run a mutation, you first call `useUpdateUserSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSelfMutation, { data, loading, error }] = useUpdateUserSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSelfMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSelfMutation, UpdateUserSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSelfMutation, UpdateUserSelfMutationVariables>(UpdateUserSelfDocument, options);
      }
export type UpdateUserSelfMutationHookResult = ReturnType<typeof useUpdateUserSelfMutation>;
export type UpdateUserSelfMutationResult = Apollo.MutationResult<UpdateUserSelfMutation>;
export type UpdateUserSelfMutationOptions = Apollo.BaseMutationOptions<UpdateUserSelfMutation, UpdateUserSelfMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    createdAt
    updatedAt
    account
    password
    isActivated
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const CreateCertDocument = gql`
    mutation CreateCert($input: CreateCertInput!) {
  createCert(input: $input) {
    id
    createdAt
    updatedAt
    domain
  }
}
    `;
export type CreateCertMutationFn = Apollo.MutationFunction<CreateCertMutation, CreateCertMutationVariables>;

/**
 * __useCreateCertMutation__
 *
 * To run a mutation, you first call `useCreateCertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertMutation, { data, loading, error }] = useCreateCertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCertMutation(baseOptions?: Apollo.MutationHookOptions<CreateCertMutation, CreateCertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCertMutation, CreateCertMutationVariables>(CreateCertDocument, options);
      }
export type CreateCertMutationHookResult = ReturnType<typeof useCreateCertMutation>;
export type CreateCertMutationResult = Apollo.MutationResult<CreateCertMutation>;
export type CreateCertMutationOptions = Apollo.BaseMutationOptions<CreateCertMutation, CreateCertMutationVariables>;
export const UpdateCertDocument = gql`
    mutation UpdateCert($input: UpdateCertInput!) {
  updateCert(input: $input) {
    id
    createdAt
    updatedAt
    domain
  }
}
    `;
export type UpdateCertMutationFn = Apollo.MutationFunction<UpdateCertMutation, UpdateCertMutationVariables>;

/**
 * __useUpdateCertMutation__
 *
 * To run a mutation, you first call `useUpdateCertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCertMutation, { data, loading, error }] = useUpdateCertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCertMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCertMutation, UpdateCertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCertMutation, UpdateCertMutationVariables>(UpdateCertDocument, options);
      }
export type UpdateCertMutationHookResult = ReturnType<typeof useUpdateCertMutation>;
export type UpdateCertMutationResult = Apollo.MutationResult<UpdateCertMutation>;
export type UpdateCertMutationOptions = Apollo.BaseMutationOptions<UpdateCertMutation, UpdateCertMutationVariables>;
export const DeleteCertDocument = gql`
    mutation DeleteCert($id: ID!) {
  deleteCert(id: $id) {
    id
  }
}
    `;
export type DeleteCertMutationFn = Apollo.MutationFunction<DeleteCertMutation, DeleteCertMutationVariables>;

/**
 * __useDeleteCertMutation__
 *
 * To run a mutation, you first call `useDeleteCertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertMutation, { data, loading, error }] = useDeleteCertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCertMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCertMutation, DeleteCertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCertMutation, DeleteCertMutationVariables>(DeleteCertDocument, options);
      }
export type DeleteCertMutationHookResult = ReturnType<typeof useDeleteCertMutation>;
export type DeleteCertMutationResult = Apollo.MutationResult<DeleteCertMutation>;
export type DeleteCertMutationOptions = Apollo.BaseMutationOptions<DeleteCertMutation, DeleteCertMutationVariables>;
export const TestDocument = gql`
    query Test {
  test
}
    `;

/**
 * __useTestQuery__
 *
 * To run a query within a React component, call `useTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestQuery(baseOptions?: Apollo.QueryHookOptions<TestQuery, TestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestQuery, TestQueryVariables>(TestDocument, options);
      }
export function useTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestQuery, TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestQuery, TestQueryVariables>(TestDocument, options);
        }
export type TestQueryHookResult = ReturnType<typeof useTestQuery>;
export type TestLazyQueryHookResult = ReturnType<typeof useTestLazyQuery>;
export type TestQueryResult = Apollo.QueryResult<TestQuery, TestQueryVariables>;
export const ServerDocument = gql`
    query Server($id: ID!) {
  server(id: $id) {
    id
    createdAt
    updatedAt
    name
    key
    comment
  }
}
    `;

/**
 * __useServerQuery__
 *
 * To run a query within a React component, call `useServerQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServerQuery(baseOptions: Apollo.QueryHookOptions<ServerQuery, ServerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServerQuery, ServerQueryVariables>(ServerDocument, options);
      }
export function useServerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServerQuery, ServerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServerQuery, ServerQueryVariables>(ServerDocument, options);
        }
export type ServerQueryHookResult = ReturnType<typeof useServerQuery>;
export type ServerLazyQueryHookResult = ReturnType<typeof useServerLazyQuery>;
export type ServerQueryResult = Apollo.QueryResult<ServerQuery, ServerQueryVariables>;
export const ServersDocument = gql`
    query Servers {
  servers {
    id
    createdAt
    updatedAt
    name
    key
    comment
  }
}
    `;

/**
 * __useServersQuery__
 *
 * To run a query within a React component, call `useServersQuery` and pass it any options that fit your needs.
 * When your component renders, `useServersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServersQuery({
 *   variables: {
 *   },
 * });
 */
export function useServersQuery(baseOptions?: Apollo.QueryHookOptions<ServersQuery, ServersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServersQuery, ServersQueryVariables>(ServersDocument, options);
      }
export function useServersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServersQuery, ServersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServersQuery, ServersQueryVariables>(ServersDocument, options);
        }
export type ServersQueryHookResult = ReturnType<typeof useServersQuery>;
export type ServersLazyQueryHookResult = ReturnType<typeof useServersLazyQuery>;
export type ServersQueryResult = Apollo.QueryResult<ServersQuery, ServersQueryVariables>;
export const ReverseProxyDocument = gql`
    query ReverseProxy($id: ID!) {
  reverseProxy(id: $id) {
    id
    server {
      id
      createdAt
      updatedAt
      name
      key
      comment
    }
    createdAt
    updatedAt
    isActivated
    name
    cdnDomain
    actualDomain
    cdnPath
    actualPath
    isWildCard
    isMaintaining
    maintenanceTitle
    maintenanceDesktopImageFilename
    maintenanceMobileImageFilename
    isTracking
    trackingPath
    port
    proto
    comment
  }
}
    `;

/**
 * __useReverseProxyQuery__
 *
 * To run a query within a React component, call `useReverseProxyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReverseProxyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReverseProxyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReverseProxyQuery(baseOptions: Apollo.QueryHookOptions<ReverseProxyQuery, ReverseProxyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReverseProxyQuery, ReverseProxyQueryVariables>(ReverseProxyDocument, options);
      }
export function useReverseProxyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReverseProxyQuery, ReverseProxyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReverseProxyQuery, ReverseProxyQueryVariables>(ReverseProxyDocument, options);
        }
export type ReverseProxyQueryHookResult = ReturnType<typeof useReverseProxyQuery>;
export type ReverseProxyLazyQueryHookResult = ReturnType<typeof useReverseProxyLazyQuery>;
export type ReverseProxyQueryResult = Apollo.QueryResult<ReverseProxyQuery, ReverseProxyQueryVariables>;
export const ReverseProxiesDocument = gql`
    query ReverseProxies {
  reverseProxies {
    id
    server {
      id
      createdAt
      updatedAt
      name
      key
      comment
    }
    createdAt
    updatedAt
    isActivated
    name
    cdnDomain
    actualDomain
    cdnPath
    actualPath
    isWildCard
    isMaintaining
    maintenanceTitle
    maintenanceDesktopImageFilename
    maintenanceMobileImageFilename
    isTracking
    trackingPath
    port
    proto
    comment
  }
}
    `;

/**
 * __useReverseProxiesQuery__
 *
 * To run a query within a React component, call `useReverseProxiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReverseProxiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReverseProxiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReverseProxiesQuery(baseOptions?: Apollo.QueryHookOptions<ReverseProxiesQuery, ReverseProxiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReverseProxiesQuery, ReverseProxiesQueryVariables>(ReverseProxiesDocument, options);
      }
export function useReverseProxiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReverseProxiesQuery, ReverseProxiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReverseProxiesQuery, ReverseProxiesQueryVariables>(ReverseProxiesDocument, options);
        }
export type ReverseProxiesQueryHookResult = ReturnType<typeof useReverseProxiesQuery>;
export type ReverseProxiesLazyQueryHookResult = ReturnType<typeof useReverseProxiesLazyQuery>;
export type ReverseProxiesQueryResult = Apollo.QueryResult<ReverseProxiesQuery, ReverseProxiesQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    id
    createdAt
    updatedAt
    account
    password
    isActivated
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    createdAt
    updatedAt
    account
    password
    isActivated
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const CertDocument = gql`
    query Cert($id: ID!) {
  cert(id: $id) {
    id
    createdAt
    updatedAt
    domain
  }
}
    `;

/**
 * __useCertQuery__
 *
 * To run a query within a React component, call `useCertQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCertQuery(baseOptions: Apollo.QueryHookOptions<CertQuery, CertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertQuery, CertQueryVariables>(CertDocument, options);
      }
export function useCertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertQuery, CertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertQuery, CertQueryVariables>(CertDocument, options);
        }
export type CertQueryHookResult = ReturnType<typeof useCertQuery>;
export type CertLazyQueryHookResult = ReturnType<typeof useCertLazyQuery>;
export type CertQueryResult = Apollo.QueryResult<CertQuery, CertQueryVariables>;
export const CertsDocument = gql`
    query Certs {
  certs {
    id
    createdAt
    updatedAt
    domain
  }
}
    `;

/**
 * __useCertsQuery__
 *
 * To run a query within a React component, call `useCertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCertsQuery(baseOptions?: Apollo.QueryHookOptions<CertsQuery, CertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertsQuery, CertsQueryVariables>(CertsDocument, options);
      }
export function useCertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertsQuery, CertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertsQuery, CertsQueryVariables>(CertsDocument, options);
        }
export type CertsQueryHookResult = ReturnType<typeof useCertsQuery>;
export type CertsLazyQueryHookResult = ReturnType<typeof useCertsLazyQuery>;
export type CertsQueryResult = Apollo.QueryResult<CertsQuery, CertsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    createdAt
    updatedAt
    account
    password
    isActivated
    role
    lastLoginIP
    lastLoginAt
    loginFailedIPs
    loginFailedAts
    comment
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;