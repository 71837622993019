import {
    DeleteTwoTone,
} from '@ant-design/icons';
import { Button, Image, Space, Upload } from 'antd'
import React from 'react'
import type { RcFile } from 'antd/es/upload/interface';
import { BASE_URL } from '../../../store/client';

type Props = {
    isImageURL?: boolean
    defaultValue?: any
    onUpdate?: (newValue: string, filename: string) => void
    onDelete?: () => void
    disabled?: boolean
}

export const getBase64 = (img: RcFile, callback: (url: string, filename: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string, img.name));
    reader.readAsDataURL(img);
};

export const UploadableImage: React.FC<Props> = ({ defaultValue, onUpdate, onDelete, isImageURL, disabled }) => {
    return <Space>
        {
            defaultValue ?
                <Image height="120px" src={isImageURL ? `${BASE_URL}${defaultValue}` : `data:image/*;IMG_;base64,${defaultValue}`} /> :
                null
        }
        {
            onDelete && defaultValue ?
                <Button disabled={disabled} type="dashed" onClick={() => {
                    const yes = window.confirm("確定刪除圖片?")
                    if (!yes) {
                        return
                    }
                    onDelete()
                }} title="刪除圖片">
                    <DeleteTwoTone twoToneColor={"red"} />
                </Button> : null
        }
        <Upload
            disabled={disabled}
            accept='.jpeg,.png,.jpg,.webm,.gif,.ico'
            maxCount={1}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={file => {
                if (!onUpdate) {
                    return
                }
                getBase64(file, (data, filename) => {
                    const content = data.split("base64,", 2)[1]
                    onUpdate(
                        content,
                        filename,    
                    )
                })
                return false
            }}
        >上傳圖片
        </Upload>



    </Space>
}