import {
    DeleteTwoTone,
} from '@ant-design/icons';
import { Button, Form, Input, Row, Space, Switch, Typography } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import React, { useContext, useState } from 'react'
import { EditableText } from '../../components/UI/EditableText/EditableText';
import { UploadableImage } from '../../components/UI/UploadableImage/UploadableImage';
import { CreateReverseProxyDocument, CreateReverseProxyInput, CreateReverseProxyMutation, CreateReverseProxyMutationVariables, DeleteReverseProxyDocument, DeleteReverseProxyMutation, DeleteReverseProxyMutationVariables, ReverseProxy, UpdateReverseProxyDocument, UpdateReverseProxyMutation, UpdateReverseProxyMutationVariables } from '../../graphql/generated';
import { BaseActionType } from '../../store/baseActions'
import { client } from '../../store/client'
import { contextStore } from '../../store/context'
import { handleError } from '../utils/errors'

type Props = {}

export const ReverseProxiesPage: React.FC = (props: Props) => {
    const { reverseProxies, reverseProxiesDispatch, readonly } = useContext(contextStore)
    const [createReverseProxyInput, setCreateReverseProxyInput] = useState<CreateReverseProxyInput>({
        name: "",
        cdnDomain: "",
        actualDomain: "",
        cdnPath: "",
        actualPath: "",
        isWildCard: false,
        comment: "",
    })

    const update = (variables: UpdateReverseProxyMutationVariables) => {
        client.mutate<UpdateReverseProxyMutation, UpdateReverseProxyMutationVariables>({
            mutation: UpdateReverseProxyDocument,
            variables,
        })
        .then(({data, errors}) => {
            if (handleError(data, errors)) {
                return
            }
            if (!data) {
                return
            }

            reverseProxiesDispatch({
                type: BaseActionType.Update,
                payload: data.updateReverseProxy,
            })
        })
    }

    const columns: ColumnsType<ReverseProxy> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '名稱',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <EditableText disabled={readonly} defaultValue={record.name} onSave={newValue => {
                    update({
                        input: {
                            id: record.id,
                            name: newValue,
                        }
                    })
                }}/>
            }
        },
        {
            title: '伺服器',
            dataIndex: 'server',
            key: 'server',
            render: (_, record) => {
                return record.server ? record.server.name : ""
            }
        },
        {
            title: '啟用中',
            dataIndex: 'isActivated',
            key: 'isActivated',
            render: (_, record) => {
                return <Switch disabled={readonly} checked={record.isActivated} onChange={val => {
                    update({
                        input: {
                            id: record.id,
                            isActivated: val,
                        }
                    })
                }}/>
            }
        },
        {
            title: 'CDN網域',
            dataIndex: 'cdnDomain',
            key: 'cdnDomain',
            render: (_, record) => {
                return <EditableText disabled={readonly} defaultValue={record.cdnDomain} onSave={newValue => {
                    update({
                        input: {
                            id: record.id,
                            cdnDomain: newValue,
                        }
                    })
                }}/>
            }
        },
        {
            title: '後台網域',
            dataIndex: 'actualDomain',
            key: 'actualDomain',
            render: (_, record) => {
                return <EditableText disabled={readonly} defaultValue={record.actualDomain} onSave={newValue => {
                    update({
                        input: {
                            id: record.id,
                            actualDomain: newValue,
                        }
                    })
                }}/>
            }
        },
        {
            title: 'CDN路徑',
            dataIndex: 'cdnPath',
            key: 'cdnPath',
            render: (_, record) => {
                return <EditableText disabled={readonly} defaultValue={record.cdnPath} onSave={newValue => {
                    update({
                        input: {
                            id: record.id,
                            cdnPath: newValue,
                        }
                    })
                }}/>
            }
        },
        {
            title: '後台路徑',
            dataIndex: 'actualPath',
            key: 'actualPath',
            render: (_, record) => {
                return <EditableText disabled={readonly} defaultValue={record.actualPath} onSave={newValue => {
                    update({
                        input: {
                            id: record.id,
                            actualPath: newValue,
                        }
                    })
                }}/>
            }
        },
        {
            title: '含子網域',
            dataIndex: 'isWildCard',
            key: 'isWildCard',
            render: (_, record) => {
                return <Switch disabled={readonly} checked={record.isWildCard} onChange={val => {
                    update({
                        input: {
                            id: record.id,
                            isWildCard: val,
                        }
                    })
                }}/>
            }
        },
        {
            title: '監控中',
            dataIndex: 'isTracking',
            key: 'isTracking',
            render: (_, record) => {
                return <Switch disabled={readonly} checked={record.isTracking} onChange={val => {
                    update({
                        input: {
                            id: record.id,
                            isTracking: val,
                        }
                    })
                }}/>
            }
        },
        {
            title: '維護中',
            dataIndex: 'isMaintaining',
            key: 'isMaintaining',
            render: (_, record) => {
                return <Switch disabled={readonly} checked={record.isMaintaining} onChange={val => {
                    update({
                        input: {
                            id: record.id,
                            isMaintaining: val,
                        }
                    })
                }}/>
            }
        },
        {
            title: '維護中網頁標題',
            dataIndex: 'maintenanceTitle',
            key: 'maintenanceTitle',
            render: (_, record) => {
                return <EditableText disabled={readonly} defaultValue={record.maintenanceTitle} onSave={newValue => {
                    update({
                        input: {
                            id: record.id,
                            maintenanceTitle: newValue,
                        }
                    })
                }}/>
            }
        },
        {
            title: '維護中手機圖片',
            dataIndex: 'maintenanceMobileImageFilename',
            key: 'maintenanceMobileImageFilename',
            render: (_, record) => {
                return <UploadableImage 
                    disabled={readonly}
                    defaultValue={record.maintenanceMobileImageFilename ? `/images/m-${record.maintenanceMobileImageFilename}` : ""}
                    isImageURL
                    onUpdate={newValue => {
                        update({
                            input: {
                                id: record.id,
                                maintenanceMobileImageData: newValue,
                            }
                        })
                    }}
                />
            }
        },
        {
            title: '維護中桌機圖片',
            dataIndex: 'maintenanceDesktopImageFilename',
            key: 'maintenanceDesktopImageFilename',
            render: (_, record) => {
                return <UploadableImage 
                    disabled={readonly}
                    defaultValue={record.maintenanceDesktopImageFilename ? `/images/d-${record.maintenanceDesktopImageFilename}` : ""}
                    isImageURL
                    onUpdate={newValue => {
                        update({
                            input: {
                                id: record.id,
                                maintenanceDesktopImageData: newValue,
                            }
                        })
                    }}
                />
            }
        },
        {
            title: '監控路徑',
            dataIndex: 'trackingPath',
            key: 'trackingPath',
            render: (_, record) => {
                return <EditableText disabled={readonly} defaultValue={record.trackingPath} onSave={newValue => {
                    update({
                        input: {
                            id: record.id,
                            trackingPath: newValue,
                        }
                    })
                }}/>
            }
        },
        {
            title: '備註',
            dataIndex: 'comment',
            key: 'comment',
            render: (_, record) => {
                return <EditableText disabled={readonly} defaultValue={record.comment} onSave={newValue => {
                    update({
                        input: {
                            id: record.id,
                            comment: newValue,
                        }
                    })
                }}/>
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => {
                const onDelete = () => {
                    const yes = window.confirm(`是否刪除反向代理: ${record.name}`)
                    if (!yes) {
                        return
                    }
                    client.mutate<DeleteReverseProxyMutation, DeleteReverseProxyMutationVariables>({
                        mutation: DeleteReverseProxyDocument,
                        variables: {
                            id: record.id,
                        }
                    })
                        .then(({ data, errors }) => {
                            if (handleError(data, errors)) {
                                return
                            }
                            if (!data) {
                                return
                            }
                            reverseProxiesDispatch({
                                type: BaseActionType.Delete,
                                payload: data.deleteReverseProxy,
                            })
                        })
                }
                return <Space>
                    <Button disabled={readonly} onClick={onDelete} title="刪除反向代理">
                        <DeleteTwoTone twoToneColor={"red"} />
                    </Button>
                </Space>
            },
        },
    ]

    console.log(createReverseProxyInput)

    const canCreate = createReverseProxyInput.name !== "" && 
    createReverseProxyInput.cdnDomain !== "" &&
    createReverseProxyInput.actualDomain !== "" && !readonly
    const onCreate = () => {
        if (!canCreate) {
            return
        }
        client.mutate<CreateReverseProxyMutation, CreateReverseProxyMutationVariables>({
            mutation: CreateReverseProxyDocument,
            variables: {
                input: createReverseProxyInput,
            }
        })
            .then(({ data, errors }) => {
                if (handleError(data, errors)) {
                    return
                }
                if (!data) {
                    return
                }

                reverseProxiesDispatch({
                    type: BaseActionType.Create,
                    payload: data.createReverseProxy,
                })
            })
    }
    return <div>
        <Row>
            <Form style={{ border: "solid gray", padding: "20px", margin: "5px", display: "flex", flexDirection: "column" }} title="搜尋">
                <Typography.Title level={3}>新增反向代理</Typography.Title>

                <Form.Item required label="名稱" >
                    <Input name="name" value={createReverseProxyInput.name} onChange={e => setCreateReverseProxyInput({
                        ...createReverseProxyInput,
                        name: e.currentTarget.value,
                    })}></Input>
                </Form.Item>
                <Form.Item required label="CDN網域" >
                    <Input name="cdnDomain" value={createReverseProxyInput.cdnDomain} onChange={e => setCreateReverseProxyInput({
                        ...createReverseProxyInput,
                        cdnDomain: e.currentTarget.value,
                    })}></Input>
                </Form.Item>
                <Form.Item required label="後台網域" >
                    <Input name="actualDomain" value={createReverseProxyInput.actualDomain} onChange={e => setCreateReverseProxyInput({
                        ...createReverseProxyInput,
                        actualDomain: e.currentTarget.value,
                    })}></Input>
                </Form.Item>
                <Form.Item label="CDN路徑" >
                    <Input name="cdnPath" value={createReverseProxyInput.cdnPath} onChange={e => setCreateReverseProxyInput({
                        ...createReverseProxyInput,
                        cdnPath: e.currentTarget.value,
                    })}></Input>
                </Form.Item>
                <Form.Item label="後台路徑" >
                    <Input name="actualPath" value={createReverseProxyInput.actualPath} onChange={e => setCreateReverseProxyInput({
                        ...createReverseProxyInput,
                        actualPath: e.currentTarget.value,
                    })}></Input>
                </Form.Item>
                <Form.Item label="包含子網域" >
                    <Switch checked={createReverseProxyInput.isWildCard} onClick={b => setCreateReverseProxyInput({
                        ...createReverseProxyInput,
                        isWildCard: b,
                    })}></Switch>
                </Form.Item>
                <Form.Item label="維護中網頁標題" >
                    <Input name="maintenanceTitle" allowClear value={createReverseProxyInput.maintenanceTitle ?? ""} onChange={e => setCreateReverseProxyInput({
                        ...createReverseProxyInput,
                        maintenanceTitle: e.currentTarget.value,
                    })}></Input>
                </Form.Item>
                <Form.Item label="備註" >
                    <Input name="comment" value={createReverseProxyInput.comment} onChange={e => setCreateReverseProxyInput({
                        ...createReverseProxyInput,
                        comment: e.currentTarget.value,
                    })}></Input>
                </Form.Item>
                <Button disabled={!canCreate} type="primary" onClick={onCreate}>新增</Button>
            </Form>
        </Row>
        <div style={{ overflowX: "scroll" }}>
            <Table
                dataSource={reverseProxies}
                columns={columns}
            />
        </div>
    </div>
}
