import {
    DeleteTwoTone,
} from '@ant-design/icons';
import { Button, Form, Input, Space, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import Table, { ColumnsType } from 'antd/lib/table'
import React, { useContext, useState } from 'react'
import { EditableText } from '../../components/UI/EditableText/EditableText'
import { CreateCertDocument, CreateCertInput, CreateCertMutation, CreateCertMutationVariables, DeleteCertDocument, DeleteCertMutation, DeleteCertMutationVariables, Cert, UpdateCertDocument, UpdateCertMutation, UpdateCertMutationVariables } from '../../graphql/generated'
import { BaseActionType } from '../../store/baseActions'
import { client } from '../../store/client'
import { contextStore } from '../../store/context'
import { handleError } from '../utils/errors'
import { UploadableImage } from '../../components/UI/UploadableImage/UploadableImage';

type Props = {}

export const CertsPage: React.FC = (props: Props) => {
    const { certs, certsDispatch, readonly } = useContext(contextStore)
    const [createInput, setCreateInput] = useState<CreateCertInput>({
        domain: "",
        sslCert: "",
        sslKey: "",
    })

    const onCreate = () => {
        if (readonly) {
            return
        }
        client.mutate<CreateCertMutation, CreateCertMutationVariables>({
            mutation: CreateCertDocument,
            variables: {
                input: createInput,
            }
        })
            .then(({ data, errors }) => {
                if (handleError(data, errors)) {
                    return
                }
                if (!data) {
                    return
                }

                certsDispatch({
                    type: BaseActionType.Create,
                    payload: data.createCert,
                })

                setCreateInput({
                    domain: "",
                    sslCert: "",
                    sslKey: "",
                })
            })
    }
    const update = (variables: UpdateCertMutationVariables) => {
        if (readonly) {
            return
        }
        client.mutate<UpdateCertMutation, UpdateCertMutationVariables>({
            mutation: UpdateCertDocument,
            variables,
        })
            .then(({ data, errors }) => {
                if (handleError(data, errors)) {
                    return
                }
                if (!data) {
                    return
                }

                certsDispatch({
                    type: BaseActionType.Update,
                    payload: data.updateCert,
                })

            })
    }

    const columns: ColumnsType<Cert> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '網域',
            dataIndex: 'domain',
            key: 'domain',
            render: (_, record) => {
                return <EditableText defaultValue={record.domain} onSave={newValue => update({
                    input: {
                        id: record.id,
                        domain: newValue,
                    }
                })} />
            }
        },
        {
            title: '創造日',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, reocrd) => {
                return <div>{new Date(reocrd.createdAt).toLocaleDateString()}</div>
            }
        },
        {
            title: '更新時間',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (_, reocrd) => {
                return <div>{new Date(reocrd.updatedAt).toLocaleString()}</div>
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => {
                const onDelete = () => {
                    const yes = window.confirm(`是否刪除伺服器: ${record.id}`)
                    if (!yes) {
                        return
                    }
                    client.mutate<DeleteCertMutation, DeleteCertMutationVariables>({
                        mutation: DeleteCertDocument,
                        variables: {
                            id: record.id,
                        }
                    })
                        .then(({ data, errors }) => {
                            if (handleError(data, errors)) {
                                return
                            }
                            if (!data) {
                                return
                            }
                            certsDispatch({
                                type: BaseActionType.Delete,
                                payload: data.deleteCert,
                            })
                        })
                }
                return <Space>
                    <Button onClick={onDelete} title="刪除伺服器">
                        <DeleteTwoTone twoToneColor={"red"} />
                    </Button>
                </Space>
            },
        },
    ];

    const canCreate = !readonly && createInput.domain !== "" && createInput.sslCert !== "" && createInput.sslKey !== ""

    return <div>
        <div style={{ display: "flex" }}>
            <Form style={{ border: "solid gray", padding: "20px", margin: "5px", display: "flex", flexDirection: "column" }} title="搜尋">
                <Typography.Title level={3}>新增伺服器</Typography.Title>

                <Form.Item name="domain" label="網域" rules={[{ required: true }]} >
                    <Input value={createInput.domain} onInput={e => setCreateInput({ ...createInput, domain: e.currentTarget.value })}></Input>
                </Form.Item>
                <Form.Item name="sslCert" label="SSL Cert" rules={[{ required: true }]} >
                    <TextArea value={createInput.sslCert} onInput={e => setCreateInput({ ...createInput, sslCert: e.currentTarget.value })}></TextArea>
                </Form.Item>
                <Form.Item name="sslKey" label="SSL Key" rules={[{ required: true }]} >
                    <TextArea value={createInput.sslKey} onInput={e => setCreateInput({ ...createInput, sslKey: e.currentTarget.value })}></TextArea>
                </Form.Item>

                <Button disabled={!canCreate} type="primary" onClick={onCreate}>新增</Button>
            </Form>
        </div>
        <div style={{ overflowX: "scroll" }}>
            <Table
                dataSource={certs}
                columns={columns}
            />
        </div>
    </div>
}
