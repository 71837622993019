import { Button, Form, Input } from 'antd'
import React, { useContext, useState } from 'react'
import { FormBox } from '../../components/UI/FormBox/FormBox'
import { CreateAuthTokenDocument, CreateAuthTokenMutation, CreateAuthTokenMutationVariables } from '../../graphql/generated'
import { client } from '../../store/client'
import { contextStore } from '../../store/context'
import { handleError } from '../utils/errors'

type Props = {}

export const LoginPage: React.FC = (props: Props) => {
    const [account, setAccount] = useState("")
    const [password, setPassword] = useState("")

    const { me } = useContext(contextStore)
    const onLogin = () => {
        client.mutate<CreateAuthTokenMutation, CreateAuthTokenMutationVariables>({
            mutation: CreateAuthTokenDocument,
            variables: {
                input: {
                    account,
                    password,
                }
            }
        })
        .then(({data, errors}) => {
            if (handleError(data, errors)) {
                return
            }
            if (!data) {
                return
            }
            if (data) {
                console.log("set accessToken", data.createAuthToken.accessToken)
                localStorage.setItem("accessToken", data.createAuthToken.accessToken)
                window.location.reload()
            }
        })
    }
    return <FormBox show={me === null} formBoxClosed={() => { }}>
        <Form>
            <label>帳號</label>
            <Input type="text" autoComplete='username' value={account} onChange={e => setAccount(e.currentTarget.value.trim())}></Input>
            <label>密碼</label>
            <Input type="password" autoComplete='current-password' value={password} onChange={e => setPassword(e.currentTarget.value)} onPressEnter={onLogin}></Input>
            <Button type="primary" onClick={onLogin}>登入</Button>
        </Form>
    </FormBox>
}
