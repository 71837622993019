import {
    DeleteTwoTone,
} from '@ant-design/icons';
import { Button, Form, Input, Space, Typography } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import React, { useContext, useState } from 'react'
import { EditableText } from '../../components/UI/EditableText/EditableText'
import { CreateServerDocument, CreateServerInput, CreateServerMutation, CreateServerMutationVariables, DeleteServerDocument, DeleteServerMutation, DeleteServerMutationVariables, Server, UpdateServerDocument, UpdateServerMutation, UpdateServerMutationVariables } from '../../graphql/generated'
import { BaseActionType } from '../../store/baseActions'
import { client } from '../../store/client'
import { contextStore } from '../../store/context'
import { handleError } from '../utils/errors'

type Props = {}

export const ServersPage: React.FC = (props: Props) => {
    const { servers, serversDispatch, readonly } = useContext(contextStore)
    const [createInput, setCreateInput] = useState<CreateServerInput>({
        name: "",
        comment: "",
    })

    const onCreate = () => {
        if (readonly) {
            return
        }
        client.mutate<CreateServerMutation, CreateServerMutationVariables>({
            mutation: CreateServerDocument,
            variables: {
                input: createInput,
            }
        })
            .then(({ data, errors }) => {
                if (handleError(data, errors)) {
                    return
                }
                if (!data) {
                    return
                }

                serversDispatch({
                    type: BaseActionType.Create,
                    payload: data.createServer,
                })
            })
    }
    const update = (variables: UpdateServerMutationVariables) => {
        if (readonly) {
            return
        }
        client.mutate<UpdateServerMutation, UpdateServerMutationVariables>({
            mutation: UpdateServerDocument,
            variables,
        })
            .then(({ data, errors }) => {
                if (handleError(data, errors)) {
                    return
                }
                if (!data) {
                    return
                }

                serversDispatch({
                    type: BaseActionType.Update,
                    payload: data.updateServer,
                })

            })
    }

    const columns: ColumnsType<Server> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '名稱',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '金鑰',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: '創造日',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, reocrd) => {
                return <div>{new Date(reocrd.createdAt).toLocaleDateString()}</div>
            }
        },
        {
            title: '更新時間',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (_, reocrd) => {
                return <div>{new Date(reocrd.updatedAt).toLocaleString()}</div>
            }
        }, {
            title: '備註',
            dataIndex: 'comment',
            key: 'comment',
            render: (_, record) => {
                const onSave = (newValue: string) => {
                    update({
                        input: {
                            id: record.id,
                            comment: newValue,
                        }
                    })
                }
                return <EditableText defaultValue={record.comment} onSave={onSave} />
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => {
                const onDelete = () => {
                    const yes = window.confirm(`是否刪除伺服器: ${record.id}`)
                    if (!yes) {
                        return
                    }
                    client.mutate<DeleteServerMutation, DeleteServerMutationVariables>({
                        mutation: DeleteServerDocument,
                        variables: {
                            id: record.id,
                        }
                    })
                        .then(({ data, errors }) => {
                            if (handleError(data, errors)) {
                                return
                            }
                            if (!data) {
                                return
                            }
                            serversDispatch({
                                type: BaseActionType.Delete,
                                payload: data.deleteServer,
                            })
                        })
                }
                return <Space>
                    <Button onClick={onDelete} title="刪除伺服器">
                        <DeleteTwoTone twoToneColor={"red"} />
                    </Button>
                </Space>
            },
        },
    ];

    return <div>
        <div style={{ display: "flex" }}>
            <Form style={{ border: "solid gray", padding: "20px", margin: "5px", display: "flex", flexDirection: "column" }} title="搜尋">
                <Typography.Title level={3}>新增伺服器</Typography.Title>

                <Form.Item name="name" label="名稱" rules={[{ required: true }]} >
                    <Input value={createInput.name} onInput={e => setCreateInput({ ...createInput, name: e.currentTarget.value })}></Input>
                </Form.Item>
                <Form.Item name="comment" label="備註" >
                    <Input value={createInput.comment} onInput={e => setCreateInput({ ...createInput, comment: e.currentTarget.value })}></Input>
                </Form.Item>
                <Button disabled={readonly} type="primary" onClick={onCreate}>新增</Button>
            </Form>
        </div>
        <div style={{ overflowX: "scroll" }}>
            <Table
                dataSource={servers}
                columns={columns}
            />
        </div>
    </div>
}
