import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { client } from './store/client';
import { App } from './App';
import { contextStore, initState } from './store/context'
// import 'antd/dist/antd.min.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <contextStore.Provider value={initState}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {/* <Layout> */}
          <App />
        {/* </Layout> */}
      </BrowserRouter>
      </contextStore.Provider>
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
