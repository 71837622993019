import { message } from "antd"

export  const handleError = (data: any, errors: any) => {
    if (errors) {
        message.error(errors[0].message)
        return true
    }
    if (!data) {
        message.error("無資料回傳")
        return true
    }
    return false
}