import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export const BASE_URL = process.env.NODE_ENV === "production" ? '': "http://localhost:8080"
export const ENDPOINT = BASE_URL + '/api/v0/graphql'

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
}

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('accessToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });


const cache = new InMemoryCache();
const httpLink = new HttpLink({
    uri: ENDPOINT,
});

// const enhancedHttpLink = transformerLink.concat(link);

export const client = new ApolloClient<NormalizedCacheObject>({
    cache,
    link: authLink.concat(httpLink),
    defaultOptions: defaultOptions,
});
