import { Button, Form, Input, Typography } from 'antd'
import React, { useContext, useState } from 'react'
import { UpdateUserSelfDocument, UpdateUserSelfMutation, UpdateUserSelfMutationVariables } from '../../graphql/generated'
import { client } from '../../store/client'
import { contextStore } from '../../store/context'
import { handleError } from '../utils/errors'


type Props = {}

export const MeSettingPage: React.FC = (props: Props) => {
  const { me, readonly } = useContext(contextStore)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [repeatNewPassword, setRepeatNewPassword] = useState("")

  if (!me) {
    return null
  }

  const onChangePassword = () => {
    if (readonly) {
      return
    }
    const input = {
      oldPassword,
      newPassword,
      repeatNewPassword,
    }
    console.log(input)
    client.mutate<UpdateUserSelfMutation, UpdateUserSelfMutationVariables>({
      mutation: UpdateUserSelfDocument,
      variables: {
        input,
      }
    })
      .then(({ data, errors }) => {
        if (handleError(data, errors)) {
          return
        }
        if (!data) {
          return
        }
        localStorage.setItem("accessToken", "")
        localStorage.setItem("refreshToken", "")
        alert("修改密碼成功")
        window.location.reload()
      })
  }
  

  return <div>
    <Form style={{ border: "solid", padding: "20px", margin: "5px", display: "flex", flexDirection: "column", width: "clamp(200px, 100%, 800px)" }}>
      <Typography.Title level={3}>修改密碼</Typography.Title>
      <Form.Item name="oldPassword" label="舊密碼" rules={[{ required: true }]}>
        <Input type="password" value={oldPassword} onInput={e => setOldPassword(e.currentTarget.value)}></Input>
      </Form.Item>
      <Form.Item name="newPassword" label="新密碼" rules={[{ required: true }]}>
        <Input type="password" value={newPassword} onInput={e => setNewPassword(e.currentTarget.value)}></Input>
      </Form.Item>
      <Form.Item name="repeatNewPassword" label="重複新密碼" rules={[{ required: true }]}>
        <Input type="password" value={repeatNewPassword} onInput={e => setRepeatNewPassword(e.currentTarget.value)} onPressEnter={onChangePassword}></Input>
      </Form.Item>
      <Button disabled={readonly} type="primary" onClick={onChangePassword} >修改密碼</Button>

    </Form>
  </div>
}