import { createContext } from "react";
import { Cert, ReverseProxy, Server, User } from "../graphql/generated";
import { BaseActions } from "./baseActions";

const VoidFunction = () => { }

export interface ContextState {
    reverseProxies: ReverseProxy[]
    reverseProxiesDispatch: React.Dispatch<BaseActions<ReverseProxy>>
    servers: Server[]
    serversDispatch: React.Dispatch<BaseActions<Server>>
    users: User[]
    usersDispatch: React.Dispatch<BaseActions<User>>
    certs: Cert[]
    certsDispatch: React.Dispatch<BaseActions<Cert>>
    me: User | null
    setMe: React.Dispatch<User | null>
    readonly: boolean
    setReadonly: React.Dispatch<boolean>
}


export const initState: ContextState = {
    reverseProxies: [],
    reverseProxiesDispatch: VoidFunction,
    servers: [],
    serversDispatch: VoidFunction,
    users: [],
    usersDispatch: VoidFunction,
    certs: [],
    certsDispatch: VoidFunction,
    me: null,
    setMe: VoidFunction,
    readonly: true,
    setReadonly: VoidFunction
}


export const contextStore = createContext<ContextState>(initState)
