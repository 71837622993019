import {
    UserOutlined,
    HomeTwoTone,
    CloudServerOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { Role } from '../../graphql/generated';
import { contextStore } from '../../store/context';

type Props = {}

export const Navigation = (props: Props) => {

    const { me } = useContext(contextStore)

    if (me === null) {
        return <Menu></Menu>
    }

    const items: MenuProps['items'] = [
        
        {
            key: 'reverse-proxies',
            icon: <Link to="/reverse-proxies"><HomeTwoTone /></Link>,
            label: "反向代理",
        },
        {
            key: 'me',
            icon: <Link to="/me"><UserOutlined /></Link>,
            label: "個人設定",
        },
    ]

    if (me.role === Role.Admin) {

        items.push({
            key: 'users',
            icon: <Link to="/users"><UserOutlined /></Link>,
            label: "使用者管理",
        })
        items.push({
            key: 'certs',
            icon: <Link to="/certs"><UserOutlined /></Link>,
            label: "憑證管理",
        })

        items.push({
            key: 'servers',
            icon: <Link to="/servers"><CloudServerOutlined /></Link>,
            label: "伺服器管理",
        })
    }

    return <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['site']}
        items={items}
    />
}
