import {
    WarningTwoTone,
    UnlockTwoTone,
} from '@ant-design/icons';
import { Button, Form, Input, Select, Space, Switch, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useContext, useEffect, useState } from 'react'
import { EditableText } from '../../components/UI/EditableText/EditableText'
import { CreateUserDocument, CreateUserMutation, CreateUserMutationVariables, DeleteUserDocument, DeleteUserMutation, DeleteUserMutationVariables, Role, UpdateUserDocument, UpdateUserMutation, UpdateUserMutationVariables, User, UsersDocument, UsersQuery, UsersQueryVariables } from '../../graphql/generated'
import { BaseActionType } from '../../store/baseActions'
import { client } from '../../store/client'
import { contextStore } from '../../store/context'
import { handleError } from '../utils/errors'

type Props = {}

export const UsersPage: React.FC = (props: Props) => {
    const { users, usersDispatch, readonly } = useContext(contextStore)
    const [newAccount, setNewAccount] = useState("")
    const [newRole, setNewRole] = useState<Role | null>(null)
    useEffect(() => {
        client.query<UsersQuery, UsersQueryVariables>({
            query: UsersDocument,
        })
            .then(({ data, errors }) => {
                if (handleError(data, errors)) {
                    return
                }
                usersDispatch({
                    type: BaseActionType.List,
                    payload: data.users,
                })
            })
    }, [usersDispatch])

    const update = (variables: UpdateUserMutationVariables) => {
        if (readonly) {
            return
        }
        client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
            mutation: UpdateUserDocument,
            variables,
        })
            .then(({ data, errors }) => {
                if (handleError(data, errors)) {
                    return
                }
                if (!data) {
                    return
                }
                usersDispatch({
                    type: BaseActionType.Update,
                    payload: data.updateUser,
                })
            })
    }

    const onCreateUser = () => {
        if (newRole === null) {
            return
        }
        if (readonly) {
            return
        }
        client.mutate<CreateUserMutation, CreateUserMutationVariables>({
            mutation: CreateUserDocument,
            variables: {
                input: {
                    account: newAccount,
                    role: newRole,
                }
            }
        })
            .then(({ data, errors }) => {
                if (handleError(data, errors)) {
                    return
                }
                if (!data) {
                    return
                }

                alert(`請記下新使用者的密碼: ${data.createUser.password}`)

                usersDispatch({
                    type: BaseActionType.Create,
                    payload: data.createUser,
                })

                setNewAccount("")
            })
    }

    const columns: ColumnsType<User> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '帳號',
            dataIndex: 'account',
            key: 'account',
        }, {
            title: '角色',
            dataIndex: 'role',
            key: 'role',
        }, {
            title: '創建日',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => {
                return new Date(record.createdAt).toLocaleDateString()
            }
        }, {
            title: '更新時間',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (_, record) => {
                return new Date(record.updatedAt).toLocaleTimeString()
            }
        }, {
            title: '最後登入時間',
            dataIndex: 'lastLoginAt',
            key: 'lastLoginAt',
            render: (_, record) => {
                return new Date(record.lastLoginAt).toLocaleTimeString()
            }
        }, {
            title: '最後登入IP',
            dataIndex: 'lastLoginIP',
            key: 'lastLoginIP',
            render: (_, record) => {
                return record.lastLoginIP
            }
        }, {
            title: '登入失敗次數',
            dataIndex: 'loginFailedTimes',
            key: 'loginFailedTimes',
            render: (_, record) => {
                return record.loginFailedAts.length.toString()
            }
        }, {
            title: '已啟用',
            dataIndex: 'isActivated',
            key: 'isActivated',
            render: (_, record) => {
                return <Switch disabled={readonly}
                    onChange={() => {
                        update({
                            input: {
                                id: record.id,
                                isActivated: !record.isActivated,
                            }
                        })
                    }}
                    checked={record.isActivated}
                />
            }
        }, {
            title: '備註',
            dataIndex: 'comment',
            key: 'comment',
            render: (_, record) => {
                const onSave = (newValue: string) => {
                    update({
                        input: {
                            id: record.id,
                            comment: newValue,
                        }
                    })
                }
                return <EditableText disabled={readonly} defaultValue={record.comment} onSave={onSave} />
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => {
                const onDelete = () => {
                    const yes = window.confirm(`是否刪除使用者: ${record.account}`)
                    if (!yes) {
                        return
                    }
                    client.mutate<DeleteUserMutation, DeleteUserMutationVariables>({
                        mutation: DeleteUserDocument,
                        variables: {
                            id: record.id,
                        }
                    })
                        .then(({ data, errors }) => {
                            if (handleError(data, errors)) {
                                return
                            }
                            if (!data) {
                                return
                            }
                            usersDispatch({
                                type: BaseActionType.Delete,
                                payload: data.deleteUser,
                            })
                        })
                }

                const onUnlock = () => {
                    const yes = window.confirm(`解鎖使用者: ${record.account}?`)
                    if (!yes) {
                        return
                    }
                    update({
                        input: {
                            id: record.id,
                            unlockLoginFails: true,
                        }
                    })
                }
                return <Space>
                    <Button disabled={readonly} onClick={onDelete} title="刪除使用者">
                        <WarningTwoTone twoToneColor={"red"} />
                    </Button>
                    <Button disabled={readonly} onClick={onUnlock} title="解鎖使用者">
                        <UnlockTwoTone twoToneColor={"blue"} />
                    </Button>
                </Space>
            },
        },
    ];

    const canCreate = newAccount !== "" && newRole !== null && !readonly
    return <div>
        <div style={{ display: "flex" }}>
            <Form style={{ border: "solid gray", padding: "20px", margin: "5px", display: "flex", flexDirection: "column" }} title="搜尋">
                <Typography.Title level={3}>新增使用者</Typography.Title>

                <Form.Item required label="帳號" rules={[{ required: true }]} >
                    <Input name="account" value={newAccount} onInput={e => setNewAccount(e.currentTarget.value)}></Input>
                </Form.Item>
                <Form.Item name="role" label="角色" >
                    <Select onChange={v => setNewRole(v)}>
                        <Select.Option key={Role.Manager}>經理</Select.Option>
                        <Select.Option key={Role.Admin}>管理員</Select.Option>
                    </Select>
                </Form.Item>
                <Button disabled={!canCreate} type="primary" onClick={onCreateUser}>新增</Button>
            </Form>
        </div>
        <div style={{ overflowX: "scroll" }}>
            <Table
                dataSource={users}
                columns={columns}
            />
        </div>
    </div>
}