import './App.css'
import { Layout } from 'antd';
import React, { useEffect, useReducer } from 'react';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigation } from './components/Navigation/Navigation';
import { TopHeader } from './components/TopHeader/TopHeader';
import { LoginPage } from './containers/LoginPage/LoginPage';
import { UsersPage } from './containers/UsersPage/UsersPage';
import { CertsDocument, CertsQuery, CertsQueryVariables, MeDocument, MeQuery, MeQueryVariables, ReverseProxiesDocument, ReverseProxiesQuery, ReverseProxiesQueryVariables, ServersDocument, ServersQuery, ServersQueryVariables, User } from './graphql/generated';
import { client } from './store/client';
import { ContextState, contextStore } from './store/context';
import { initUsersState, usersReducer } from './store/users';
import { MeSettingPage } from './containers/MeSettingPage/MeSettingPage';
import { handleError } from './containers/utils/errors';
import { BaseActionType } from './store/baseActions';
import { initReverseProxiesState, reverseProxiesReducer } from './store/reverseProxies';
import { ReverseProxiesPage } from './containers/ReverseProxiesPage/ReverseProxiesPage';
import { initServersState, serversReducer } from './store/servers';
import { ServersPage } from './containers/ServersPage/ServersPage';
import { certsReducer, initCertsState } from './store/certs';
import { CertsPage } from './containers/CertsPage/CertsPage';

const { Content, Footer, Sider } = Layout;

export const App: React.FC = () => {
  const [users, usersDispatch] = useReducer(usersReducer, initUsersState)
  const [reverseProxies, reverseProxiesDispatch] = useReducer(reverseProxiesReducer, initReverseProxiesState)
  const [servers, serversDispatch] = useReducer(serversReducer, initServersState)
  const [certs, certsDispatch] = useReducer(certsReducer, initCertsState)
  const [me, setMe] = useState<User | null>(null)
  const [readonly, setReadonly] = useState(true)

  const context: ContextState = {
    reverseProxies,
    reverseProxiesDispatch,
    servers,
    serversDispatch,
    users,
    usersDispatch,
    certs,
    certsDispatch,
    me,
    setMe,
    readonly,
    setReadonly,
  }

  useEffect(() => {
    if (me !== null) {
      return
    }
    client.query<MeQuery, MeQueryVariables>({
      query: MeDocument,
    })
      .then(({ data, errors }) => {
        if (errors) {
          console.log(errors)
          return
        }
        setMe(data.me)
      })
  }, [me])

  useEffect(() => {
    client.query<ReverseProxiesQuery, ReverseProxiesQueryVariables>({
      query: ReverseProxiesDocument,
    })
      .then(({ data, errors }) => {
        if (handleError(data, errors)) {
          return
        }
        if (!data) {
          return
        }
        reverseProxiesDispatch({
          type: BaseActionType.List,
          payload: data.reverseProxies,
        })
      })

    client.query<ServersQuery, ServersQueryVariables>({
      query: ServersDocument,
    })
      .then(({ data, errors }) => {
        if (handleError(data, errors)) {
          return
        }
        if (!data) {
          return
        }
        serversDispatch({
          type: BaseActionType.List,
          payload: data.servers,
        })
      })

    client.query<CertsQuery, CertsQueryVariables>({
      query: CertsDocument,
    })
      .then(({ data, errors }) => {
        if (handleError(data, errors)) {
          return
        }
        if (!data) {
          return
        }

        certsDispatch({
          type: BaseActionType.List,
          payload: data.certs,
        })
      })
  }, [])

  return <contextStore.Provider value={context}>
    <Layout style={{ minHeight: '100vh' }}>
      {me ? null : <LoginPage />}
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo" />
        <Navigation />
      </Sider>
      <Layout>
        <TopHeader />
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

            <Routes>
              <Route path="/" element={<ReverseProxiesPage />} />
              <Route path="/reverse-proxies" element={<ReverseProxiesPage />} />
              <Route path="/servers" element={<ServersPage />} />
              <Route path="/me" element={<MeSettingPage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/certs" element={<CertsPage />} />
            </Routes>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Y.H. Design</Footer>
      </Layout>
    </Layout>
  </contextStore.Provider>
};
